const ThunderheadTextHandlers = {
    'A': function (tag, textObject) {
        $(tag).prop('href', textObject['link']);
        $(tag).text(textObject['text'])
    },
    'DEFAULT': function (tag, textObject) {
        $(tag).text(textObject);
    },
};

function setUpThunderhead(widget) {
    const assetPath = widget.data('thunderhead-asset-path');
    const testId = widget.data('thunderhead-test-id');

    if (
        typeof window[testId].processed !== "undefined"
        && window[testId].processed === true
    ) {
        console.debug(typeof(window[testId].processed));
        console.debug(window[testId].processed);

        return;
    }

    window[testId].on("pointsProcessed", function (event) {
        const popupData = event.response.optimizations.find(function (optimization) {
            return optimization.path === assetPath;
        });

        if (typeof popupData === "undefined") {
            console.debug(typeof popupData);
            console.debug(popupData);

            return;
        }

        if (typeof popupData.data.actions[0].asset.content === "undefined") {
            return;
        }

        const widgetText = $.parseJSON(popupData.data.actions[0].asset.content);
        widget.find('.thunderhead-text').each(function () {
            const item = $(this);
            const textKey = $(item).data('text-key');
            const tagName = typeof ThunderheadTextHandlers[$(item).prop('tagName').toUpperCase()] === "undefined"
                ? 'DEFAULT'
                : $(item).prop('tagName').toUpperCase();

            if (typeof widgetText[textKey] !== "undefined") {
                ThunderheadTextHandlers[tagName](item, widgetText[textKey]);
            } else {
                console.debug(tagName);
            }
        });

        const interactionsParameters = popupData.data.actions[0].asset.responses;
        const positiveInteractionParameters = interactionsParameters.find(function (interaction) {
            return interaction.sentiment === 'positive';
        });
        const neutralInteractionParameters = interactionsParameters.find(function (interaction) {
            return interaction.sentiment === 'neutral';
        });
        const negativeInteractionParameters = interactionsParameters.find(function (interaction) {
            return interaction.sentiment === 'negative';
        });

        widget.find('.thunderhead-interaction-positive').on('click', function (e) {
            e.preventDefault();
            window[testId].sendResponseCode(positiveInteractionParameters.code);

            if (typeof e.currentTarget.href !== "undefined") {
                window.location.href = e.currentTarget.href;
            }
        });

        widget.find('.thunderhead-interaction-negative').on('click', function () {
            window[testId].sendResponseCode(negativeInteractionParameters.code);
            $(widget).removeClass('opened').addClass('closed');
        });

        const widgetAppearanceDelaySeconds = $(widget).data('appearance-delay-seconds') || 60;
        setTimeout(
            function () {
                $(widget).removeClass('closed').addClass('opened');
                window[testId].sendResponseCode(neutralInteractionParameters.code);
            },
            parseInt(widgetAppearanceDelaySeconds) * 1000
        );
    });
}

$(document).ready(function() {
    $('.thunderhead-test').each(function() {
        const widget = $(this);
        const testId = $(widget).data('thunderhead-test-id');

        if (typeof window[testId] === "undefined") {
            document.addEventListener(testId,function () {
                setUpThunderhead(widget);
            });
        } else {
            setUpThunderhead(widget);
        }
    });
});
